<template>
 <div class="content">
   <app-cells position="between">
     <app-cells :indent="false">
       <h1 class="title title--theme">Благодарственные письма</h1>
     </app-cells>
     <v-select
       v-if="userIsAdmin"
       :reduce="item => item.id"
       :options="delegation_options"
       :filterable="true"
       :clearable="false"
       v-model="user_delegation_id"
       @input="onSetDelegation"
       label="name"
       placeholder="Выберите делегацию"
       class="select select--minw"
     >
       <template #open-indicator>
         <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
           <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
           <path d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z" fill="#fff"></path>
         </svg>
       </template>
       <template #option="option">
         <div class="select__item d-center">{{ option.name }}</div>
       </template>
       <template #selected-option="option">
         <div class="selected d-center">{{ option.name }}</div>
       </template>
       <template #no-options>Ничего не найдено</template>
     </v-select>
   </app-cells>
   <app-cells position="start">
     <app-button
       size="link"
       :class="{'btn--loading': download_letters}"
       @click="onDownloadLetters"
     >
       <img src="@/assets/img/file/import-icon.svg" alt="Скачать">
       <span>Скачать все благодарственные письма</span>
     </app-button>
   </app-cells>
   <h2 class="title title--medium title--indent">Индивидуальные благодарственные письма (Максимум 10 шт)</h2>
   <app-cells position="start">
     <router-link :to="{ name: 'thank-note-create' }" class="btn btn--link btn--link-icon">
       <span>Добавить индивидуальное благодарственное письмо</span>
     </router-link>
   </app-cells>
   <app-table-head
     @update-limiter="onUpdateLimiter"
     :count="table.items.length"
     :page="pagination.page"
     :all="pagination.count"
   />
   <v-client-table
     @row-click="onRowClick"
     :data="table.items"
     :columns="table.columns"
     :options="table.options"
     ref="tableDefault"
     class="table-default table-default--dynamic"
   >
     <template #first_name="props">
       {{ props.row.first_name }} {{ props.row.last_name }} {{ props.row.patronymic }}
     </template>
   </v-client-table>
   <paginate
     v-if="pagination.pages > 1"
     v-model="pagination.page"
     :page-count="pagination.pages"
     :clickHandler="onClickPagination"
     :prev-text="'<'"
     :next-text="'>'"
     :container-class="'pagination'"
     :page-class="'pagination__item'"
     :page-link-class="'pagination__link'"
     :prev-class="'pagination__prev'"
     :next-class="'pagination__next'"
     :next-link-class="'pagination__link-next'"
     :prev-link-class="'pagination__link-prev'"
   />
   <app-cells position="start">
     <h2 class="title title--medium title--indent">Благодарственные письма на организацию</h2>
   </app-cells>
   <app-cells position="start">
     <router-link :to="{ name: 'thank-note-create', query: { type: 'org' } }" class="btn btn--link btn--link-icon">
       <span>Добавить благодарственное письмо на организацию</span>
     </router-link>
   </app-cells>
   <app-table-head
     @update-limiter="onUpdateLimiterOrg"
     :count="table_org.items.length"
     :page="pagination_org.page"
     :all="pagination_org.count"
   />
   <v-client-table
     @row-click="onRowClick"
     :data="table_org.items"
     :columns="table_org.columns"
     :options="table_org.options"
     ref="tableDefaultOrg"
     class="table-default table-default--dynamic"
   >
     <template #first_name="props">
       {{ props.row.first_name }} {{ props.row.last_name }} {{ props.row.patronymic }}
     </template>
   </v-client-table>
   <paginate
     v-if="pagination_org.pages > 1"
     v-model="pagination_org.page"
     :page-count="pagination_org.pages"
     :clickHandler="onClickPaginationOrg"
     :prev-text="'<'"
     :next-text="'>'"
     :container-class="'pagination'"
     :page-class="'pagination__item'"
     :page-link-class="'pagination__link'"
     :prev-class="'pagination__prev'"
     :next-class="'pagination__next'"
     :next-link-class="'pagination__link-next'"
     :prev-link-class="'pagination__link-prev'"
   />
 </div>
</template>

<script>
import { getDelegations, patchUser, getThankNoteList, getThankNotesExport } from '@/http'

export default {
  name: 'ThanksNoteList',
  data() {
    return {
      download_letters: false,
      table: {
        items: [],
        columns: ['id', 'first_name', 'org', 'status.name'],
        options: {
          headings: {
            'id': 'ID',
            'first_name': 'ФИО',
            'org': 'Организация',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного благодарственного письма',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit: 20,
      table_org: {
        items: [],
        columns: ['id', 'org', 'status.name'],
        options: {
          headings: {
            'id': 'ID',
            'org': 'Организация',
            'status.name': 'Статус',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Вы еще не добавили ни одного благодарственного письма',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: row => `table-default__row table-default__row--${row.status.code}`,
        },
      },
      pagination_org: {
        pages: 1,
        page: 1,
        count: 0,
      },
      table_limit_org: 20,
      delegation_options: [],
      user_delegation_id: null,
    }
  },
  computed: {
    userIsAdmin() {
      return this.$store.state.user_role === 'admin'
    },
  },
  created() {
    this.fetchItems()
    this.fetchItemsOrg()
    setTimeout(() => {
      if (this.userIsAdmin) {
        getDelegations()
          .then(response => {
            this.delegation_options = response.data
            this.user_delegation_id = this.$store.state.delegation
            if (this.$route.params.delegation_id) {
              this.user_delegation_id = this.$route.params.delegation_id
              this.onSetDelegation()
            }
          })
      }
    }, 200)
  },
  methods: {
    onDownloadLetters() {
      this.download_letters = true
      const delegation = this.delegation_options.find(item => item.id === this.user_delegation_id)
      getThankNotesExport()
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Благодарственные письма ${delegation.name}.zip`)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .finally(() => {
          this.download_letters = false
        })
    },
    fetchItems() {
      getThankNoteList(this.pagination.page, this.table_limit, 'individual')
        .then(response => {
          this.table.items = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    fetchItemsOrg() {
      getThankNoteList(this.pagination_org.page, this.table_limit_org, 'org')
        .then(response => {
          this.table_org.items = response.data.results
          this.pagination_org.pages = response.data.pages
          this.pagination_org.count = response.data.count
        })
    },
    onRowClick(row) {
      this.$router.push({ name: 'thank-note-detail', params: { id: row.row.id }, query: { type: row.row.type } })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.pagination.page = 1
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems()
    },
    onClickPagination(page) {
      this.pagination.page = page
      this.fetchItems()
    },
    onUpdateLimiterOrg(data) {
      this.table_limit_org = data
      this.pagination_org.page = 1
      this.$refs.tableDefaultOrg.setLimit(this.table_limit_org)
      this.fetchItemsOrg()
    },
    onClickPaginationOrg(page) {
      this.pagination_org.page = page
      this.fetchItemsOrg()
    },
    onSetDelegation() {
      this.$store.commit('setDelegation', this.user_delegation_id)
      patchUser({ delegation: this.user_delegation_id })
        .then(response => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Делегация успешно изменена'
          })
          this.fetchItems()
          this.fetchItemsOrg()
          this.$store.commit('setDelegationHash', response.data.delegation_hash)
        })
    },
  }
}
</script>

<style scoped>

</style>
